<template>
  <div class="bg-white py-1">
    <!-- <div class="TitleList d-flex justify-content-between ">
        <h3 class="text-right" >{{ $t(ProdList.title) }}</h3>
        <div class="ShowMoreBtn" >
          <router-link class='ProdBoxBtn' :to="{ name:'ProdByTag', params:{ProdByTag:ProdList.title}}" >{{$t('ShowMore')}}</router-link>
          <a :href="ProdList.link" class="pull-left">{{$t('ShowMore')}}</a> 
        </div>
        <h3 class="text-right" >{{ $t('translation1') }}</h3> 
      </div> -->
    <b-row align-v="center" class="pt-2 px-0 flickity-card m-1">
      <b-col align="start" class="flickity-title">
        <h6 v-if="$mq === 'sm'">
        {{ $t(ProdList.title) }}
        </h6>
        <h4 v-else>
        {{ $t(ProdList.title) }}
        </h4>
      </b-col>
      <b-col align="end">
        <b-button
          variant="outline-secondary"
         :to="{ name:'ProdByTag', params:{ProdByTag:ProdList.value}}"
          class="py-1"
          >{{ $t("ShowMore") }}</b-button
        >
      </b-col>
    </b-row>
    <b-skeleton-wrapper :loading="ProdListLoading">
      <template #loading>
        <ProdListLoader></ProdListLoader>
      </template>
      <div class="ProdList">
        <!-- Desktop Display -->
        <mq-layout :mq="['md', 'lg']">
          <div class="container-fluid">
            <div class="row" v-if="ProdList.Display === 'list'">
              <ProdCard
                v-for="Product in DeskList"
                v-bind:key="Product.id"
                v-bind:Product="Product"
                class="col-sm-2 px-1"
              ></ProdCard>
            </div>
          </div>
        </mq-layout>

        <mq-layout :mq="['md', 'lg']">
          <Flickity
            ref="flickity"
            :options="flickityOptions"
            v-if="ProdList.Display === 'full' && ProdList.items.length != 0"
          >
            <ProdCard
              class="col-sm-2 carousel-cell"
              v-for="Product in ProdList.items.data"
              v-bind:key="Product.id"
              v-bind:Product="Product"
            ></ProdCard>
          </Flickity>
        </mq-layout>
        <!-- End Desktop Display -->

        <!-- Mobile Display -->
        <mq-layout :mq="['sm']">
          <div v-if="ProdList.mobileDisplay === 'grid3'" :mq="['sm']">
            <div class="container-fluid">
              <div class="row">
                <ProdGridBox
                  v-for="Product in ProdList.items.data"
                  v-bind:key="Product.id"
                  v-bind:Product="Product"
                  class="col-4 col-sm-3 GridItem grid-discount"
                ></ProdGridBox>
              </div>
            </div>
          </div>
        </mq-layout>
        <mq-layout :mq="['sm']">
          <div
            v-if="ProdList.mobileDisplay === 'grid' && FilterArr.length > 0"
            :mq="['sm']"
          >
            <div class="container-fluid">
              <div class="row">
                <ProdCard
                  v-for="Product in FilterArr"
                  v-bind:key="Product.id"
                  v-bind:Product="Product"
                  class="col-6 col-sm-3 GridItem hideAddToCart"
                ></ProdCard>
              </div>
            </div>
          </div>
        </mq-layout>
        <mq-layout :mq="['sm']">
          <div
            class="scrollmenu"
            v-if="ProdList.mobileDisplay === 'slider'"
            :mq="['sm']"
          >
            <ProdCard
              v-for="Product in ProdList.items.data"
              v-bind:key="Product.id"
              v-bind:Product="Product"
              class="col-5"
            ></ProdCard>
          </div>
        </mq-layout>
        <!-- End Mobile Display -->
      </div>
    </b-skeleton-wrapper>
  </div>
</template>

<script>
import Flickity from "vue-flickity";
import ProdCard from "../items/ProdCard.vue";
import ProdListLoader from "../widgets/PordListLoader.vue";
import ProdGridBox from "../items/ProdGridBox.vue";
export default {
  name: "ProdList",
  props: ["ProdList"],
  components: {
    ProdCard,
    ProdGridBox,
    ProdListLoader,
    Flickity,
  },
  data() {
    return {
      ProdListLoading: false,
      FilterArr: this.ProdList.items.data.slice(0, 4),
      DeskList: this.ProdList.items.data.slice(0, 6),
      flickityOptions: {
        initialIndex: 3,
        pageDots: false,
        wrapAround: true,
        imagesLoaded: true,

        // any options from Flickity can be used
      },
    };
  },
  watch: {
    ProdList(newValue) {
      if (newValue.items.length > 0) {
        this.ProdListLoading = false;
      }
    },
  },
  mounted() {},
};
</script>

<style scoped>
.carousel-cell {
  padding-left: 0 !important;
  padding-right: 0 !important;
  margin: 0 4px;
}

.flickity-viewport {
  height: 380px !important;
}
</style>