<template>
  <div class="container-fluid">
    <div class="row">
      <b-col sm="3">
        <SidePage></SidePage>
      </b-col>
      <b-col sm="9">
        <b-skeleton-wrapper :loading="FavoriteLoading">
          <template #loading>
            <b-container fluid>
              <landingLoader></landingLoader>
            </b-container>
          </template>
          <b-container>
            <!-- <b-row class="SubCat" v-if="!FavoriteLoading">
                        <b-col cols="3" class="CatItem d-flex justify-content-center" v-for="Category in Categories"  v-bind:key="Category.id">
                            <CatSlideItem v-bind:Category='Category'></CatSlideItem>
                        </b-col>
                    </b-row> -->

            <b-row v-if="FavoriteProducts.favorite">
              <b-row
                v-if="FavoriteProducts.favorite.items.data.length > 0"
                align-h="center"
                align-v="center"
                class="bg-white mt-2 w-100 mx-auto rounded p-3"
              >
                <label>
                  {{ $t("Item Count") }} :
                  <strong
                    >{{ FavoriteProducts.favorite.items.data.length }}
                  </strong>
                </label>
              </b-row>
              <div v-if="!FavoriteLoading" class="ProdList container my-3">
                <b-row>
                  <b-col
                    sm="3"
                    cols="6"
                    class="px-1 my-1"
                    v-for="Product in FavoriteProducts.favorite.items.data"
                    v-bind:key="Product.id"
                  >
                    <ProdCard
                      v-bind:Product="Product"
                      @removeProd="getResults()"
                    ></ProdCard>
                  </b-col>
                </b-row>
                <b-col
                  v-if="FavoriteProducts.favorite.items.data.length == 0"
                  class="text-center mx-auto my-5"
                >
                  <b-row align-h="center">
                    <h4 class="mb-3" style="font-weight: 500">
                      {{ $t("there is no favorive product") }}
                    </h4>
                  </b-row>

                  <b-row align-h="center">
                    <b-button
                      variant="primary "
                      pill
                      class="rounded"
                      @click="seeProd"
                    >
                      {{ $t("See Products") }}</b-button
                    >
                  </b-row>
                </b-col>

                <b-row>
                  <pagination
                    v-if="!FavoriteLoading"
                    :data="FavoriteProducts.favorite.items"
                    @pagination-change-page="getResults"
                    :limit="8"
                    class="mx-auto my-3 flex-wrap"
                  ></pagination>
                </b-row>
              </div>
            </b-row>
          </b-container>

          <div v-for="item in FavoriteProducts.mobile" v-bind:key="item.title">
            <banner
              v-bind:item="item"
              v-if="item.type === 'banner' && item.mobileDisplay !== 'hide'"
            ></banner>
            <ProdList
              v-bind:ProdList="item"
              v-if="item.type === 'ProdList' && item.mobileDisplay !== 'hide'"
            >
            </ProdList>
          </div>

          <div v-for="item in FavoriteProducts.desktop" v-bind:key="item.title">
            <banner
              v-bind:item="item"
              v-if="item.type === 'banner' && item.Display !== 'hide'"
            ></banner>
            <ProdList
              v-bind:ProdList="item"
              v-if="item.type === 'ProdList' && item.Display !== 'hide'"
            ></ProdList>
          </div>
        </b-skeleton-wrapper>
      </b-col>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import banner from "../components/widgets/Banner.vue";
import landingLoader from "../components/widgets/landingLoader.vue";
import ProdList from "../components/lists/ProdList.vue";
//import CatSlideItem from '../components/items/CatSlideItem.vue'
import ProdCard from "../components/items/ProdCard.vue";
import SidePage from "../components/widgets/SidePage.vue";
import LaravelVuePagination from "laravel-vue-pagination";

export default {
  name: "Favorite",
  metaInfo: {
    title: "Favorite",
  },
  computed: {
    ...mapGetters(["FavoriteProducts"]),
  },
  components: {
    ProdList,
    ProdCard,
    banner,
    landingLoader,
    //CatSlideItem,
    SidePage,
    pagination: LaravelVuePagination,
  },
  methods: {
    ...mapActions(["getFavorite"]),
    seeProd() {
      this.$router.push({ name: "AllCat", params: { param: "All" } });
    },
    getResults(page = 1) {
      var obj = {
        breakpoint: this.$mq,
        page: page,
      };
      //this.getLanding(obj);
      this.getFavorite(obj);
    },
  },
  mounted() {
    this.$gtag.event("Favorite Page", { method: "Google" });

    this.getResults();
  },
  data() {
    return {
      FavoriteLoading: true,
    };
  },
  watch: {
    FavoriteProducts() {
      this.FavoriteLoading = false;
      //  window.scrollTo(0, 0);
    },
  },
};
</script>

<style scoped>
.CatListHeader {
  font-size: 20px;
  font-weight: bold;
  margin: 6px;
}

.CatListBody ul li a {
  text-decoration: none;
  color: #646464;
}

.ProdByCatItem {
  margin: 4px 0;
}

.SubCat {
  margin: 8px 0;
  background: white;
  border-radius: 8px;
  border-color: #e7e7e7;
  border-width: 1px;
  border-style: solid;
}
</style>