<template>
  <!-- <div class="container-fluid HomeBanner col-xl-12"  >
         <a :href="item.link" v-if="$mq === 'md' || $mq === 'lg'">
            <img class="d-block w-100" :src="require(`../../assets/banners/${item.value}`)" width="1280" height="475" :alt="item.title">
         </a>
         <a :href="item.link" v-if="$mq === 'sm'">
            <img class="img-fluid" :src="require(`../../assets/banners/${item.value}`)" width="1280" height="475" :alt="item.title">
         </a>
      </div> -->
  <b-container fluid>
    <b-row class="my-2  w-50 mx-auto">
      <b-col cols="12" sm="6" class="p-1">
        <a href="#" >
          <img
            class="img-fluid shadow rounded"
            :src="require(`../../assets/banners/${item.value}`)"
          />
        </a>
      
      </b-col>
      <b-col cols="12"  sm="6" class="p-1 ">
        <a href="#" >
          <img
            class="img-fluid shadow rounded"
            :src="require(`../../assets/banners/${item.value}`)"
          />
        </a>
      
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  props: ["item"],
};
</script>

<style>
</style>